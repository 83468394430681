import layoutInfoData from '@/layouts/monimo/layout-data/index.js';
import { useUiStore } from '../../stores/ui/ui-store';

export default function (route) {
  const arrayPath = slicePathBySlash(route);
  if (isRoutePatternCorrect(arrayPath)) {
    const pageName = arrayPath.filter(value => {
      return value.toLowerCase().search('pgi') > -1;
    });
    const dataLayout = findDataLayout(pageName.toString());
    applyLayout(dataLayout);
  } else {
    // Error case
  }
}
export const applyLayout = function applyLayout(dataLayout) {
  const defaulLayoutData = { ...layoutInfoData.totalLayoutData };
  const applyData = Object.assign({}, defaulLayoutData, dataLayout);
  useUiStore().setLayout(applyData);
};

export const isRoutePatternCorrect = function isRoutePatternCorrect(routePath) {
  return routePath && Array.isArray(routePath);
};

export const slicePathBySlash = function slicePathBySlash(route) {
  return route ? route.path.split('/') : null;
};

export const findDataLayout = function findDataLayout(pageName) {
  const keyName = Object.keys(layoutInfoData).find(key => key.toLowerCase() === pageName.toLowerCase());

  return layoutInfoData[keyName];
};
