// stores/counter.ts
import { defineStore } from 'pinia';

export const useUiStore = defineStore('ui', {
  state: () => ({
    layout: {}, // layout Data
    headerInputValue: '',
  }),
  getters: {
    // middlewar를 통해 layout의 layoutData.js를 통해 data 정의
    getLayout() {
      return this.layout;
    },
    getHeaderInput() {
      return this.headerInputValue;
    },
  },
  actions: {
    // 레이아웃 관련 actions
    setLayout(params) {
      this.layout = params;
    },
    setHeaderInput(params) {
      this.headerInputValue = params;
    },
  },
  persist: false,
});
